<template>
	<div class="section">
		<div class="column padded">
			<h2 class="h2">a playlist for you and just you</h2>
			<PlaylistSlider playlist-name="Your GroupM Playlist" :genre="topGenreUser" filled-button style="--border-color: var(--color-blue)"/>
		</div>
		<div class="column padded">
			<h2 class="h2">a playlist for your office</h2>
			<PlaylistSlider playlist-name="Your GroupM Agency's Playlist" :genre="topGenreAgency" align-right/>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.section {
	display: flex;
	flex-direction: row;
	align-items: stretch;

	@media screen and (min-width: 1440px) {
		font-size: calc(1rem + 1 * (1vw - 14.4px));
	}

	.column {
		display: flex;
		flex-direction: column;

		.h2 {
			width: 100%;
			font-size: 6rem;
			max-width: 5.5em;
			margin-bottom: 0.3em;
		}

		&:first-child {
			color: var(--color-pink);
			background-color: var(--color-blue);
			align-items: flex-start;
		}
		&:last-child {
			color: var(--color-blue);
			background-color: var(--color-pink);
			text-align: right;
			align-items: flex-end;
		}
	}
}

.not-laptop .section {
	padding-left: 3rem;

	.column {
		padding: 6rem;
		width: 50%;

		&:first-child {
			padding-right: 3rem;
		}
		&:last-child {
			padding-left: 3rem;
		}
	}
}

.laptop .section {
	flex-direction: column;
}
</style>

<script>
import PlaylistSlider from '@/components/PlaylistSlider.vue';

import {get} from 'lodash';

export default {
	props: {
		user: Object,
		agencyAggregates: Object,
	},
	components: {
		PlaylistSlider,
	},
	computed: {
		topGenreUser() {
			return get(this.user, 'top_genres[0].name');
		},
		topGenreAgency() {
			return this.agencyAggregates.top_genre;
		}
	}
}
</script>