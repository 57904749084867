import {SPOTIFY_CLIENT_ID} from '@/data/constants.js';

export default {
	methods: {
		spotifyLogin(callback) {
			localStorage.removeItem('spotifyAuthCode');

			let scopeString = '';
			let scopes = ['user-top-read', 'playlist-modify-public', 'ugc-image-upload', 'user-read-private'];
			for (let i = 0; i < scopes.length; i++) {
				scopeString += scopes[i];
				if (i < scopes.length - 1) scopeString += '%20';
			}
			let redirectUri = `${window.location.protocol}//${window.location.hostname}/spotify/login-return`;
			let url = `https://accounts.spotify.com/authorize?client_id=${SPOTIFY_CLIENT_ID}&response_type=code&redirect_uri=${redirectUri}&scope=${scopeString}`;
			let authWindow = this.popupCenter(url, 'Spotify', 400, 600);

			let timer = setInterval(function() {
				if (authWindow.closed) {
					clearInterval(timer);

					console.log('Login window closed');
					let authCode = localStorage.getItem('spotifyAuthCode');

					if (authCode) callback();
				}
			}, 1000);
		},
		popupCenter(url, title, w, h) {
			// Popup window with dual-screen fix
			// From: https://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen
			// Fixes dual-screen position                         Most browsers      Firefox
			let dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX;
			let dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY;

			let width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
			let height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

			let left = width / 2 - w / 2 + dualScreenLeft;
			let top = height / 2 - h / 2 + dualScreenTop;
			let newWindow = window.open(url, title, 'menubar=no,location=no,resizable=yes,scrollbars=yes,status=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

			// Puts focus on the newWindow
			if (window.focus) newWindow.focus();

			return newWindow;
		}
	}
};