<template>
	<div class="section full-height padded" ref="landing">
		<div class="white-bg"></div>
		<BackgroundTriangles />
		<div class="content">
			<VHeader />
			<div class="title-container">
				<div>
					<h2 class="h3 subtitle">Now Streaming</h2>
					<h1 class="h1 title">GroupM</h1>
					<h2 class="h3 subtitle second">The Sounds of Our Brand</h2>
				</div>
				<ScrollingBanner vertical :repeat="3" class="h1" :scroll-speed="$root.isMobile ? 1 : 0.5">
					<h3 class="h1" v-for="title in scrollingTitles" :key="title" v-html="title"></h3>
				</ScrollingBanner>
			</div>
			<div class="copy-container">
				<p class="copy" v-if="isStage2">
					Wondering what all the Group M agencies like to tune-in to? After a month of listening, we’ve got the results and they are <strong>HOT</strong>.
					<em>Connect with Spotify to discover your collective listening habits!</em>
				</p>
				<p class="copy" v-else>
					Want to know what each agency in Group M listens to and go in the draw to win a set of <strong>Bose Headphones</strong>?
					<em>Enter now, answer a few questions and in the following month we will reveal what your listening habits are!</em>
				</p>
				<div class="row">
					<VLink @click="connectWithSpotify" rounded style="--border-color: black;">Connect with Spotify</VLink>
					<svg-vue icon="tail-spin" class="loading-spinner" v-if="loading"></svg-vue>
				</div>
			</div>
		</div>
		<SpotifyFooter v-show="showFooter" />
	</div>
</template>

<script>
import SpotifyLogin from '@/mixins/SpotifyLogin.js';
import ScrollingBanner from '@/components/ScrollingBanner.vue';

export default {
	components: {
		ScrollingBanner,
	},
	mixins: [SpotifyLogin],
	props: {
		showFooter: Boolean,
		isStage2: Boolean,
		loading: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			scrollingTitles: ['ixed bag', 'iscellany', 'edley', 'ixture', '&eacute;lange', 'ishmash'],
		};
	},
	methods: {
		connectWithSpotify() {
			this.$gaEvent('Connect with Spotify');
			this.$emit('update:loading', true);
			this.spotifyLogin(payload => {
				this.$emit('continue', payload);
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.section {
	overflow: hidden;

	& > * {
		z-index: 0;
	}
}

.content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;
	max-width: 100%;
	position: relative;
}
.mobile .content {
	align-items: center;
}

.h1 {
	line-height: 0.9;
	white-space: nowrap;
	font-size: 11.7vw;
}
.mobile .h1 {
	font-size: 15vw;
}

.subtitle.second {
	margin-block-start: 2vw;
	margin-right: 0.7vw;
    text-align: right;
}

.title-container {
	margin-top: 5rem;
	margin-bottom: 3rem;
	display: flex;
	align-items: center;
}

.mobile .title-container {
	align-self: flex-start;
	height: 60vh;
	min-height: 30rem;
}

.scrolling-banner {
	color: var(--color-blue);
}

.not-mobile .scrolling-banner {
	position: absolute;
	top: -6rem;
	right: 0;
	height: calc(100 * var(--vh) + 6rem);
	transform: translateX(100%);
}

.mobile .scrolling-banner {
	height: 100%;
	mask-image: linear-gradient(to bottom, transparent 0%, black 10%, black 90%, transparent 100%);
	-webkit-mask-image: linear-gradient(to bottom, transparent 0%, black 10%, black 90%, transparent 100%);
}

.title {
	color: var(--color-blue-dark);
}

.mobile .copy-container {
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	& > * {
		margin-bottom: 2rem;
	}
}

.copy {
	max-width: 29rem;
}

.triangles {
	transform: translateY(calc(0.5 * var(--scroll-y)));
}

.loading-spinner {
	width: 2rem;
	height: 2rem;
	color: black;
}

.row {
	align-items: center;

	& > * {
		margin-right: 2rem;
	}
}
</style>
