<template>
	<div class="playlist-slider" :class="{ 'align-right': alignRight }">
		<Slider @slide-change="currentSlide = $event">
			<div class="grid" v-for="(chunk, index) in lazyChunkedTracks" :key="index">
				<article v-for="{ id, name, artist, image } in chunk" :key="id">
					<img :src="image" alt="" />
					<p>
						<strong>{{ artist }}</strong>
					</p>
					<p>{{ name }}</p>
				</article>
			</div>
		</Slider>
		<div class="button-container">
			<VLink rounded @click="savePlaylist" :filled="filledButton">
				Listen Now On Spotify
			</VLink>
			<svg-vue icon="tail-spin" class="loading-spinner" v-if="savingPlaylist"></svg-vue>
		</div>
	</div>
</template>

<script>
import Slider from '@/components/Slider.vue';
import { chunk } from 'lodash';

import axios from '@/axios.js';

const genreSeedsPromise = axios.get('spotify/genres/seeds').then(x => x.data.genres);

export default {
	components: {
		Slider
	},
	props: {
		playlistId: String,
		genre: String,
		genres: Array,
		filledButton: Boolean,
		alignRight: Boolean,
		playlistName: String
	},
	data() {
		return {
			tracks: [],
			currentSlide: 0,
			loadedSlides: {},
			savingPlaylist: false
		};
	},
	computed: {
		chunkedTracks() {
			return chunk(this.tracks, this.$root.isMobile ? 4 : 6);
		},
		lazyChunkedTracks() {
			return this.chunkedTracks.map((chunk, index) => (this.loadedSlides[index] ? chunk : chunk.map(track => ({ ...track, image: undefined }))));
		},
		async tracksRequest() {
			if (this.playlistId) {
				return this.$axios.get(`/spotify/playlist/${this.playlistId}`).then(x => x.data.track_items.map(x => x.track).filter(x => x));
			} else if (this.genre || this.genres) {
				const genres = this.genres || [this.genre],
					genreSeeds = await genreSeedsPromise;

				let genresValid = genres.map(genre => genreSeeds.find(genreSeed => genreSeed === genre) || genreSeeds.find(genreSeed => genreSeed.includes(genre))).filter(x => x);
				if (!genresValid.length) {
					genresValid = ['pop'];
				}

				return this.$axios.get('/spotify/tracklist/seeded', { params: { seed_genres: genresValid, limit: 24 } }).then(x => x.data.tracks);
			} else {
				return [];
			}
		},
		trackIds() {
			return this.tracks.map(x => x.id).filter(x => x);
		}
	},
	watch: {
		currentSlide: {
			handler(index) {
				this.loadSlide(index);
				this.loadSlide(index + 1);
				this.loadSlide(index - 1);
			},
			immediate: true
		},
		tracksRequest: {
			async handler(request) {
				if (request) {
					this.tracks = await request.then(x =>
						x.map(x => ({
							id: x.id,
							name: x.name,
							artist: x.artists.map(x => x.name).join(', '),
							image: x.album.images[1].url
						}))
					);
				}
			},
			immediate: true
		}
	},
	methods: {
		loadSlide(index) {
			this.$set(this.loadedSlides, index, true);
		},
		async createPlaylist() {
			try {
				let res = await axios.post('/spotify/playlist/create', {
					playlist_name: playlistName,
					track_ids: trackIDs
				});
				console.log(res.data);

				let userID = res.data.user_id;
				let playlistID = res.data.playlist_id;

				window.location.replace(`https://open.spotify.com/user/${userID}/playlist/${playlistID}`);
			} catch (err) {
				console.error(err);
				if (err.response) {
					console.error(err.response.data);
					this.$router.push({ name: 'error' });
				}
			}
		},
		async savePlaylist() {
			if (this.savingPlaylist) return;
			if (!this.playlistName) return console.error('Cannot save playlist without a playlist name');

			this.savingPlaylist = true;

			try {
				const res = await axios
					.post('/spotify/playlist/create', {
						playlist_name: this.playlistName,
						track_ids: this.trackIds
					})
					.then(x => x.data);

				console.log(res);

				const { user_id, playlist_id } = res;

				window.open(`https://open.spotify.com/user/${user_id}/playlist/${playlist_id}`);
			} finally {
				this.savingPlaylist = false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.playlist-slider {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.button-container {
	display: flex;
	align-items: center;
}

.align-right {
	align-items: flex-end;

	.button-container {
		flex-direction: row-reverse;
	}

	.loading-spinner {
		margin: 0;
		margin-right: 2rem;
	}
}

.mobile {
	.playlist-slider {
		align-items: center;
	}

	.button-container {
		margin: 2rem 0;
	}
}

.grid {
	display: grid;
	grid-template-columns: repeat(3, 33.33%);
	width: 100%;

	& > * {
		padding: 5% 10% 2rem;
	}
}

.mobile .grid {
	grid-template-columns: repeat(2, 50%);
}

img {
	width: 100%;
}

p {
	width: 100%;
	margin: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.loading-spinner {
	width: 2rem;
	height: 2rem;
	color: black;
	margin-left: 2rem;
}
</style>
