import Vue from 'vue';
import VueAnalytics from 'vue-analytics';
import router from '@/router';

import {GA_TRACKING_ID} from '@/data/constants.js'

if (GA_TRACKING_ID) {
	Vue.use(VueAnalytics, {
		id: GA_TRACKING_ID,
		router,
		autoTracking: {
			exception: true
		},
		debug: {
			enabled: process.env.NODE_ENV !== 'production',
			trace: false,
			sendHitTask: process.env.NODE_ENV === 'production',
		}
	});

	Vue.prototype.$gaEvent = function(action, label) {
		const pageName = this.$route.name || this.$route.path;
		this.$ga.event(pageName, action, label);
	};
}
