<template>
	<div class="section padded">
		<div class="white-bg"></div>
		<BackgroundTriangles class="triangles" anchor="right" />
		<h2 class="h2" style="margin-bottom: 0.3em; max-width: 12em">
			how do you and your office compare?
		</h2>
		<div class="row">
			<div class="h2" :style="{ fontSize: subjectFontSize, '--after-text': subjectModel === null ? `'are'` : `'is'` }">
				<VSelect v-model="subjectModel" class="select" :options="agencies" label="text" :reduce="x => x.id" :clearable="false" placeholder="you"> </VSelect>
			</div>
			<div class="h4 property-selector">
				<button @click="previousProperty" class="button-previous"></button>
				<div style="position: relative; width: 5em; height: 3em;">
					<transition :name="forwardsTransition ? 'forward' : 'backward'">
						<div
							style="position: absolute; top: 0; bottom: 0; right: 0; left: 0; display: flex; align-items: center; justify-content: center;"
							:key="comparisonProperty + objectModel + subjectModel"
						>
							{{ comparisonText }}
						</div>
					</transition>
				</div>
				<button @click="nextProperty" class="button-next"></button>
			</div>
			<div class="h2" :style="{ fontSize: objectFontSize, '--after-text': comparisonResult === 'equal' ? `'to'` : `'than'` }">
				<VSelect v-model="objectModel" class="select" :options="agencies" label="text" :reduce="x => x.id" :clearable="false" placeholder="office"> </VSelect>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.section {
	color: var(--color-blue);
	min-height: calc(70 * var(--vh, 1vh));
}

.select {
	--color: black;
}

.row {
	display: flex;
	flex-direction: row;
	color: black;
	width: 100%;
	align-items: center;
	padding: 3rem 0;
	margin: auto 0;

	& > *:first-child,
	& > *:last-child {
		flex: 1;
		position: relative;

		&::after {
			position: absolute;
			font-size: 2rem;
			color: var(--color-blue);
			font-weight: 400;
		}
	}

	& > *:first-child::after {
		content: var(--after-text, 'is');
		bottom: -1.5em;
		right: 0;
	}

	& > *:last-child::after {
		content: var(--after-text, 'than');
		top: -1.5em;
		left: 0;
	}
}

.laptop {
	.row {
		flex-direction: column;
		align-items: center;

		& > * {
			width: 100%;
			max-width: 40rem;
		}

		& > *:first-child {
			margin-bottom: 5rem;

			&::after {
				bottom: -3.5rem;
			}
		}

		& > *:last-child {
			margin-top: 5rem;

			&::after {
				top: -3rem;
			}
		}

		& > *:first-child::after,
		& > *:last-child::after {
			width: 100%;
			text-align: center;
		}
	}
}

.property-selector {
	margin: 0 1em;
	text-align: center;
	position: relative;
	flex: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.mobile .property-selector {
	flex-direction: row;
}

.triangles {
	transform: translateX(38.9%) scaleX(-1);
}

.button-previous,
.button-next {
	position: relative;
	width: 1.6rem;
	height: 1rem;
	padding: 1rem;
	box-sizing: content-box;
	z-index: 1;

	&::after {
		content: '';
		height: 0;
		width: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border: 0 solid transparent;
	}
}
.not-mobile .button-previous::after {
	border-width: 0 0.8rem;
	border-bottom: 1rem solid black;
}
.not-mobile .button-next::after {
	border-width: 0 0.8rem;
	border-top: 1rem solid black;
}
.mobile {
	.button-previous::after {
		border-width: 0.8rem 0;
		border-right: 1rem solid black;
	}

	.button-next::after {
		border-width: 0.8rem 0;
		border-left: 1rem solid black;
	}
}

.forward-enter-active,
.forward-leave-active,
.backward-enter-active,
.backward-leave-active {
	transition: none 0.3s;
	transition-property: opacity, transform;
}
.forward-enter-active,
.backward-leave-active {
	transform-origin: 200% center;
}
.forward-leave-active,
.backward-enter-active {
	transform-origin: -100% center;
}

.forward-enter,
.forward-leave-to,
.backward-enter,
.backward-leave-to {
	opacity: 0;
	transform: rotate(30deg);
}

.mobile {
	.forward-enter-active,
	.backward-leave-active {
		transform-origin: center -150%;
	}
	.forward-leave-active,
	.backward-enter-active {
		transform-origin: center 250%;
	}
}
</style>

<script>
import agencies from '@/data/agencies.js';
import BackgroundTriangles from '@/components/BackgroundTriangles.vue';

import { maxBy, keyBy, mapKeys } from 'lodash';

const properties = {
	energy: {
		higher: 'faster and louder',
		lower: 'slower and softer'
	},
	danceability: {
		higher: 'more danceable',
		equal: 'equally danceable',
		lower: 'more chilled-out'
	},
	valence: {
		higher: 'more cheerful',
		equal: 'equal positivity',
		lower: 'more heart-felt'
	},
	tempo: {}
};

export default {
	props: {
		object: Number,
		subject: Number,
		showFooter: Boolean,
		globalAggregates: Object,
		user: Object
	},
	components: {
		BackgroundTriangles
	},
	data() {
		return {
			comparisonPropertyIndex: 0,
			forwardsTransition: true
		};
	},
	computed: {
		agencies() {
			const agenciesWithAggregates = agencies
				.filter(x => this.globalAggregates.agencies[x.id])
				.map(x => ({
					...x,
					...this.globalAggregates[x.id]
				}));

			const { energy, danceability, valence, tempo, duration_ms } = this.user;

			const user = {
				id: null,
				text: 'you',
				energy,
				danceability,
				valence,
				tempo,
				duration_ms
			};

			return [user, ...agenciesWithAggregates];
		},
		comparisonProperty() {
			return Object.keys(properties)[this.comparisonPropertyIndex];
		},
		numberOfProperties() {
			return Object.keys(properties).length;
		},
		subjectModel: {
			get() {
				if (this.subject in this.agenciesById) {
					return this.subject;
				} else {
					return null;
				}
			},
			set(value) {
				this.$emit('update:subject', value);
			}
		},
		objectModel: {
			get() {
				if (this.object in this.agenciesById) {
					return this.object;
				} else {
					return null;
				}
			},
			set(value) {
				this.$emit('update:object', value);
			}
		},
		subjectFontSize() {
			return this.calcFontSize(this.subjectModel);
		},
		objectFontSize() {
			return this.calcFontSize(this.objectModel);
		},
		agenciesById() {
			return keyBy(this.agencies, 'id');
		},
		objectPropertyValue() {
			return this.getPropertyValue(this.objectModel);
		},
		subjectPropertyValue() {
			return this.getPropertyValue(this.subjectModel);
		},
		comparisonResult() {
			if (this.subjectPropertyValue > this.objectPropertyValue) {
				return 'higher';
			} else if (this.subjectPropertyValue < this.objectPropertyValue) {
				return 'lower';
			} else {
				return 'equal';
			}
		},
		comparisonText() {
			const override = properties[this.comparisonProperty][this.comparisonResult];
			return override || `${this.comparisonResult} ${this.comparisonProperty}`;
		}
	},
	watch: {
		subjectModel: {
			handler(newValue, oldValue) {
				if (newValue === this.objectModel) {
					if (newValue != oldValue) {
						this.objectModel = oldValue;
					} else if (this.user.agency_id in this.agenciesById) {
						this.objectModel = this.user.agency_id;
					} else {
						this.objectModel = this.agencies[1] || null;
					}
				}
			},
			immediate: true
		},
		objectModel: {
			handler(newValue, oldValue) {
				if (newValue === this.subjectModel) {
					this.subjectModel = oldValue;
				}
			}
		}
	},
	methods: {
		calcFontSize(agencyId) {
			const agency = this.agenciesById[agencyId];
			if (agency && agency.text.length >= 7) {
				return this.$root.isMobile ? '12vw' : '4vw';
			}
			return this.$root.isMobile ? '18vw' : '7vw';
		},
		getPropertyValue(agencyId) {
			// Gets a property (i.e. energy) for an agency
			// if no agencyId is provided, gets the property for the user

			const agent = agencyId !== null ? this.globalAggregates.agencies[agencyId] : this.user,
				comparisonProperty = agencyId !== null ? this.comparisonProperty : 'avg_' + this.comparisonProperty;

			return agent && agent[comparisonProperty];
		},
		nextProperty() {
			this.forwardsTransition = true;
			this.comparisonPropertyIndex = this.mod(this.comparisonPropertyIndex + 1, this.numberOfProperties);
		},
		previousProperty() {
			this.forwardsTransition = false;
			this.comparisonPropertyIndex = this.mod(this.comparisonPropertyIndex - 1, this.numberOfProperties);
		},
		mod(n, m) {
			return ((n % m) + m) % m;
		}
	}
};
</script>
