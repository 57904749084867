import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";

Vue.config.productionTip = false;

import axios from '@/axios.js';
Vue.prototype.$axios = axios;

import '@/analytics.js';

import SpotifyFooter from "@/components/SpotifyFooter.vue";
Vue.component("SpotifyFooter", SpotifyFooter);
import VHeader from "@/components/VHeader.vue";
Vue.component("VHeader", VHeader);
import VLink from "@/components/VLink.vue";
Vue.component("VLink", VLink);
import BackgroundTriangles from "@/components/BackgroundTriangles.vue";
Vue.component("BackgroundTriangles", BackgroundTriangles);

// A component provided by laravel-mix-svg-vue to inline SVG files from the resources/svg folder
import SvgVue from 'svg-vue';
Vue.use(SvgVue);

import isMobile from '@/mixins/isMobile.js';

import VSelect from 'vue-select';
Vue.component('VSelect', VSelect)

new Vue({
	router,
	mixins: [isMobile],
	render: h => h(App),
}).$mount("#app");
