<template>
	<Lottie :options="lottieOptions" class="triangles" />
</template>

<script>
import Lottie from 'vue-lottie';
import lottieBgAnim from '@/data/lottieBgAnim.json';

export default {
	components: {
		Lottie
	},
	props: {
		anchor: String,
	},
	computed: {
		preserveAspectRatio() {
			switch (this.anchor) {
				case 'left':
				default:
					return 'xMinYMax slice';
				case 'center':
					return 'xMidYMax slice';
				case 'right':
					return 'xMaxYMax slice';
			}
		},
		lottieOptions() {
			return {
				animationData: lottieBgAnim,
				rendererSettings: {
					preserveAspectRatio: this.preserveAspectRatio
				}
			};
		}
	}
};
</script>

<style scoped>
.triangles {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	pointer-events: none;
}
</style>
