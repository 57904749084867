<template>
	<transition name="shrink" @before-leave="$emit('before-leave')" @after-leave="$emit('after-leave')">
		<div v-if="!accepted" class="cookies">
			<div class="cookies-inner">
				<p>
					We use cookies on this site to enhance your user experience. By clicking any link on this page you are giving your consent for us
					to use cookies.
				</p>
				<a @click="accept">&#215;</a>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	data: () => ({
		accepted: false,
	}),
	created() {
		this.accepted = localStorage.getItem("cookiesAccepted") || false;
	},
	methods: {
		accept() {
			localStorage.setItem("cookiesAccepted", true);
			this.accepted = true;
			this.$gaEvent(this.$route.name, "Accepted Cookies");
		},
	},
};
</script>

<style scoped>
.cookies {
	width: 100%;
	background: #000000;
	color: white;
	height: 2.5rem;
	position: relative;
}

.cookies-inner {
	display: flex;
	align-items: center;
	position: absolute;
	width: 100%;
	transform: translateY(-50%);
	top: 50%;
	padding: 0 2rem;
}

.cookies-inner p {
	flex: 1 1 auto;
	margin: 0;
}

.cookies-inner a {
	font-size: 2rem;
	line-height: 0.6;
	margin-left: 1rem;
	cursor: pointer;
}

@media screen and (orientation: portrait) {
	.cookies {
		height: 5rem;
	}
}

.shrink-leave-active {
	transition-duration: 1s;
	transition-property: transform, height, opacity;
}

.shrink-leave-to {
	height: 0;
	transform: scaleY(0);
	opacity: 0;
}
</style>
