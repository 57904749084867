<template>
	<div class="scrolling-container" ref="main">
		<div class="section padded full-height">
			<VHeader />
			<div class="white-bg"></div>
			<BackgroundTriangles />
			<h1 class="h1 row spaced">
				back
				<Timer :daysLeft="daysLeft" :daysTotal="daysTotal" />
				soon
			</h1>
			<div class="copy">
				<p style="text-transform: uppercase; font-size: 1.25em; line-height: 2;">
					<strong>{{ daysLeft }} {{daysLeft === 1 ? 'day' : 'days'}}</strong> remaining
				</p>
				<p>We're busy compiling the results from all of Group M!</p>
			</div>
		</div>
		<SpotifyFooter />
	</div>
</template>

<style scoped>
.section {
	justify-content: space-between;
}

.row {
	display: flex;
	align-items: center;
	justify-content: center;
}
.mobile .row {
	flex-direction: column;
}

.spaced > * {
	margin: 0.2em;
}

.h1 {
	color: var(--color-blue);
	font-size: 12vw;
	align-self: center;
}
.mobile .h1 {
	font-size: 7rem;
}

.copy {
	font-size: 1.25rem;
}
.not-mobile .copy > * {
	margin-bottom: 0;
}
.mobile .copy {
	text-align: center;
}

.main-view-enter,
.main-view-leave-to {
	transform: translateX(100%);
}
</style>

<script>
import Timer from '@/components/Timer.vue';
import { zonedTimeToUtc } from 'date-fns-tz';
import { differenceInDays } from 'date-fns'
import { stage1DateInSydneyTime, stage2DateInSydneyTime } from '@/data/constants.js';

export default {
	components: {
		Timer,
	},
	data() {
		return {
			stage1DateInSydneyTime, 
			stage2DateInSydneyTime,
			currentDate: new Date(),
		};
	},
	computed: {
		stage1UtcDate() {
			return zonedTimeToUtc(this.stage1DateInSydneyTime, 'Australia/Sydney');
		},
		stage2UtcDate() {
			return zonedTimeToUtc(this.stage2DateInSydneyTime, 'Australia/Sydney');
		},
		daysLeft() {
			return Math.max(0, differenceInDays(this.stage2UtcDate, this.currentDate) + 1);
		},
		daysTotal() {
			return differenceInDays(this.stage2UtcDate, this.stage1UtcDate) + 1;
		}
	}
};
</script>
