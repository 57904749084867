<template>
	<component :is="is" :to="to" tag="button" :class="{ rounded, filled, outline: !filled, disabled}" @click="$emit('click', $event)" @click.native="$emit('click', $event)" :disabled="disabled">
		<slot></slot>
	</component>
</template>

<script>
export default {
	props: {
		to: Object,
		rounded: Boolean,
		filled: Boolean,
		disabled: Boolean,
	},
	computed: {
		is() {
			return this.to ? 'router-link' : 'button';
		}
	}
};
</script>

<style lang="scss" scoped>
button, a {
	display: block;
	text-transform: uppercase;
	font-weight: 900;
	font-size: 1rem;
	padding: 1.25em 1.5em;
	transition: transform 0.3s ease;
	cursor: pointer;

	@media (hover) {
		&:not(.disabled):hover {
			transform: scale(1.05);
		}
	}

	&:focus {
		outline: none;
		filter: drop-shadow(0 0 5px white);
	}
}

.rounded {
	min-width: 16rem;
	border-radius: 999px;
	border: 2px solid var(--border-color, black);
}

.filled {
	background-color: var(--color-pink);
	color: var(--color-blue);
	border: none;
}

.disabled {
	cursor: default;
	opacity: 0.5;
	transform: scale(0.95);
}
</style>
