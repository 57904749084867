<template>
	<div id="app" :class="{ mobile: $root.isMobile, 'not-mobile': !$root.isMobile, laptop: $root.isLaptop, 'not-laptop': !$root.isLaptop, iOS }" :style="`--vh: ${correctedVh}px`">
		<CookiesBanner @before-leave="startUpdatingMainHeight" @after-leave="stopUpdatingMainHeight" />
		<div id="main" ref="main">
			<transition name="main-view">
				<router-view class="main-view" />
			</transition>
		</div>
	</div>
</template>

<style lang="scss" src="@/sass/app.scss"></style>

<style scoped>
#app {
	height: 100%;
	display: flex;
	flex-direction: column;
}

#main {
	position: relative;
	flex: 1;
	overflow: hidden;
}

.main-view {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	min-height: 100%;
}
</style>

<script>
import CookiesBanner from '@/components/CookiesBanner';
export default {
	components: {
		CookiesBanner
	},
	data() {
		return {
			rafRequestId: null,
			mainHeight: 0,
			iOS: !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
		};
	},
	computed: {
		correctedVh() {
			// --vh custom property set to 1% of the height of the main element
			// address some iOS issues and resizing components when cookies banner is closed
			return this.mainHeight && this.mainHeight * 0.01;
		}
	},
	watch: {
		$route(to, from) {
			const pageTitle = to.name ? ` :: ${to.name}`: '';
			document.title = 'The Sounds of Our Brand' + pageTitle;
		}
	},
	mounted() {
		this.updateMainHeight();
		window.addEventListener('resize', this.updateMainHeight);
	},
	destroyed() {
		window.removeEventListener('resize', this.updateMainHeight);
	},
	methods: {
		startUpdatingMainHeight() {
			if (this.rafRequestId) return;
			const rafCallback = () => {
				this.updateMainHeight();
				this.rafRequestId = window.requestAnimationFrame(rafCallback);
			};
			this.rafRequestId = window.requestAnimationFrame(rafCallback);
		},
		stopUpdatingMainHeight() {
			window.cancelAnimationFrame(this.rafRequestId);
			this.rafRequestId = null;
		},
		updateMainHeight() {
			this.mainHeight = this.$refs.main.clientHeight;
		}
	}
};
</script>
