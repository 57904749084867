<template>
	<div class="section">
		<div class="padded grid-container" style="background-color: black; width: 100%; padding-top: 6rem; padding-bottom: 0;">
			<BackgroundTriangles anchor="right" />
			<div class="grid">
				<article v-for="({preline, headline, subline, fontSize}, index) in gridItemsShuffledAndWrapped" :key="index">
					<p>{{preline}}</p>
					<h3 class="h4" :style="`font-size: ${fontSize}`">{{headline}}</h3>
					<p>{{subline}}</p>
				</article>
			</div>
			<div style="width: 3rem"></div>
		</div>
		<ScrollingBanner class="padded h2" :scroll-speed="2" style="z-index: 1" :repeat="3">
			<h2 class="h2" style="white-space: nowrap; padding-left: 12rem; z-index: 3;">
				The top artist for your office is <strong>{{agencyAggregates && agencyAggregates.top_artist}}</strong>.
			</h2>
		</ScrollingBanner>
	</div>
</template>

<style scoped lang="scss">
.section {
	background-color: var(--color-blue);
}

.scrolling-banner {
	padding-top: 9rem;
	padding-bottom: 9rem;
}

.grid {
	display: grid;
	grid-template-columns: repeat(3, minmax(33%, 1fr));
	grid-template-rows: repeat(2, 25vw);
	grid-auto-columns: 0;
	grid-auto-rows: 0;
	grid-auto-flow: column;
	width: 100%;
	justify-content: stretch;
	align-items: stretch;
	justify-items: stretch;
	position: relative;
	overflow: hidden;
	font-size: 1.4vw;

	.h4 {
		margin-bottom: 1rem;
		font-weight: 700;
		font-size: 1.8em;
	}
	p {
		margin-bottom: 0;
	}

	& > * {
		width: 100%;
		text-align: center;
		padding: 2rem 1rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		min-height: 15vw;

		p {
			font-size: 1.5rem;
		}

		&:nth-child(1),
		&:nth-child(5),
		&:nth-child(10) {
			background-color: var(--color-pink);
			color: var(--color-blue);
		}
		&:nth-child(3),
		&:nth-child(6) {
			background-color: black;
			color: white;
		}
		&:nth-child(4),
		&:nth-child(7) {
			background-color: white;
			color: black;
		}
		&:nth-child(2),
		&:nth-child(8),
		&:nth-child(9) {
			background-color: var(--color-blue);
			color: var(--color-pink);
		}
	}
}

.triangles {
	left: -43%;
}

.mobile {
	.grid-container {
		overflow-x: auto;
	}
	.grid {
		width: auto;
		grid-template-columns: repeat(2, 20.5rem) 23.5rem;
		grid-template-rows: repeat(2, 20.5rem);
		padding-right: 3rem;
		overflow: visible;
		font-size: 1.3rem;

		& > * {
			min-height: 20.5rem;
			&:nth-last-child(-n+2) {
				border-right: 3rem solid black;
				width: calc(100%);
			}
		}
	}
	.scrolling-banner {
		padding-top: 6rem;
		padding-bottom: 6rem;
	}

	.triangles {
		left: -15%;
	}
}
</style>

<script>
import ScrollingBanner from '@/components/ScrollingBanner.vue';
import BackgroundTriangles from '@/components/BackgroundTriangles.vue';

import {agencies, agenciesById} from '@/data/agencies.js';

import {shuffle, flatten, capitalize, zip} from 'lodash';

export default {
	components: {
		ScrollingBanner,
		BackgroundTriangles
	},
	props: {
		agencyAggregates: Object,
	},
	data() {
		return {
			agency_id: 1,
			numOfGridItems: 6,
		}
	},
	computed: {
		gridItems() {
			if (!this.agencyAggregates) return [];
			const {top_artist, top_decade, top_genre, top_starsign, twin_agency, diversity_percent, star_sign_extremes, decade_extremes, agency_extremes} = this.agencyAggregates;
			

			const basics = [
				{
					headline: `${top_starsign}`,
					subline: `Your agency's top star sign. It's in the stars!`,
				},
				{
					headline: `${agenciesById[twin_agency].text}`,
					subline: `is the closest match to your agency's music taste. #Twinning!`,
				},
				{
					preline: `You're a bunch of`,
					headline: `${top_decade} babies`,
					subline: top_decade === '90s'
						? "We're living in a SPICE WORLD"
						: top_decade === '80s'
						? "Shoulder pads and power suits in the office"
						: top_decade === '70s'
						? "Disco fever"
						: "Groovy, baby"
				},
				{
					headline: `${capitalize(top_genre)}`,
					subline: `is your agency's top genre!`,
				},
				{
					preline: diversity_percent.toFixed(0) >= 10 ? `You're unique and diverse!` : null,
					headline: `${diversity_percent.toFixed(0)}%`,
					subline: "of your agency has joined from overseas"
				}
			]

			const agencyExtremes = [
				{
					headline: `${agenciesById[agency_extremes.danceability.max].text}`,
					subline: `loves dancing the most`,
				},
				{
					headline: `${agenciesById[agency_extremes.valence.max].text}`,
					subline: `loves the most cheerful songs`,
				}
			]

			const extremes = shuffle(agencyExtremes).slice(0, this.numOfGridItems - basics.length);

			return [
				...basics,
				...extremes,
			]
		},
		gridItemsWithFontSize() {
			const calculateFontSize = string => {
				const words = string.split(' ');
				const wordLengths = words.map(x => x.length);
				const longestWordLength = Math.max(...wordLengths);
				if (longestWordLength <= 3 && words.length === 1) {
					return '5em';
				} else if (longestWordLength <= 6 && words.length === 1) {
					return '4em';
				} else {
					return '2.4em';
				}
			}
			return this.gridItems.map(x => ({...x, fontSize: calculateFontSize(x.headline)}))
		},
		gridItemsShuffledAndWrapped() {
			const gridItems = this.gridItemsWithFontSize;
			const numOfRepeatsOfGridItems = gridItems.length ? Math.ceil(this.numOfGridItems / gridItems.length) : 1;
			const gridItemsRepeated = flatten(Array(numOfRepeatsOfGridItems).fill(gridItems).map(x => shuffle(x)));
			return gridItemsRepeated.slice(0, this.numOfGridItems);
		}
	}
};
</script>
