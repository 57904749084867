export const agencies = [
	{
		id: 1,
		text: 'GroupM Sydney',
	},
	{
		id: 2,
		text: 'GroupM Melbourne',
	},
	{
		id: 3,
		text: 'Mindshare Sydney',
	},
	{
		id: 4,
		text: 'Mindshare Melbourne',
	},
	{
		id: 5,
		text: 'Wavemaker Sydney',
	},
	{
		id: 6,
		text: 'Wavemaker Melbourne',
	},
	{
		id: 7,
		text: 'Wavemaker Brisbane',
	},
	{
		id: 8,
		text: 'Wavemaker Adelaide',
	},
	{
		id: 9,
		text: 'MediaCom Sydney',
	},
	{
		id: 10,
		text: 'MediaCom Melbourne',
	},
	{
		id: 11,
		text: 'MediaCom Brisbane',
	},
	{
		id: 12,
		text: 'Essence Sydney',
	},
	{
		id: 13,
		text: 'Essence Melbourne',
	}
];

import {keyBy} from 'lodash';

export const agenciesById = keyBy(agencies, 'id');

export default agencies;