import Vue from 'vue';

import Router from 'vue-router';
Vue.use(Router);

import Home from '@/views/Home.vue';
import StayTuned from '@/views/StayTuned.vue';

import questions from '@/data/questions.js';

const convertQueryToProps = route => ({
	isStage2,
	answers: parseArray(route.query.answers),
	agency: parseNumber(route.query.agency),
	object: parseNumber(route.query.object),
	subject: parseNumber(route.query.subject),
});

const parseNumber = x => isNaN(Number(x)) ? null : Number(x); 
const parseArray = x => {
	let result;
	try {
		result = JSON.parse(x);
	} catch (err) {}

	return Array.isArray(result) ? result : [];
}

const redirectIfSkippedAhead = (to, from, next) => {
	const props = convertQueryToProps(to);
	const nonNullAnswers = props.answers ? props.answers.filter(x => x).length : 0;
	if (to.name === 'Quiz' && !props.agency) next({name: 'Agency', replace: true});
	if (to.name === 'Quiz' && nonNullAnswers < to.params.questionNumber - 1) next({name: 'Quiz',  params: {questionNumber: 1}, query: to.query, replace: true}) 
	next();
}

import axios from '@/axios.js';
const getUser = async () => axios.get('/me').then(res => res.data.user).catch(() => null);

let userPromise = null;
let user = null;
const redirectIfUser = (locationIfTrue, locationIfFalse) => async (to, from, next) => {
	// return next(locationIfFalse || undefined); // for phase 1 testing
	userPromise = user && userPromise || getUser();
	user = user || await userPromise;
	if (user && locationIfTrue) return next(locationIfTrue);
	if (!user && locationIfFalse) return next(locationIfFalse);
	next();
}

const getSpotifyUser = async () => axios.get('/spotify/me').then(res => res.data.user).catch(() => null);

let spotifyUser = null;
const redirectIfSpotifyUser = (locationIfTrue, locationIfFalse) => async (to, from, next) => {
	spotifyUser = spotifyUser || await getSpotifyUser();
	if (spotifyUser && locationIfTrue) return next(locationIfTrue);
	if (!spotifyUser && locationIfFalse) return next(locationIfFalse);
	next();
}

import { zonedTimeToUtc } from 'date-fns-tz';
import { stage2DateInSydneyTime } from '@/data/constants.js';

const getIsStage2 = () => axios.get('/time').then(res => res.data.time).then(time => {
	const stage2UnixTimestamp = zonedTimeToUtc(stage2DateInSydneyTime, 'Australia/Sydney').valueOf() / 1000;
	return time >= stage2UnixTimestamp;
}).catch(() => null);

let isStage2 = null;
const redirectIfStage2 = (locationIfTrue, locationIfFalse) => async (to, from, next) => {
	isStage2 = isStage2 === null ? await getIsStage2() : isStage2;
	if (isStage2 && locationIfTrue) return next(locationIfTrue);
	if (!isStage2 && locationIfFalse) return next(locationIfFalse);
	next();
}

const getGlobalAggregates = () => axios.get('/aggregates/global').then(res => res.data).catch(() => null);
let globalAggregates = null;
const fetchGlobalAggregates = locationIfFail => async (to, from, next) => {
	if (!locationIfFail) {
		getGlobalAggregates();
		return next();
	}
	globalAggregates = globalAggregates === null ? await getGlobalAggregates() : globalAggregates;
	if (!globalAggregates && locationIfFail) return next(locationIfFail);
	next();
}

const getAgencyAggregates = () => {
	userPromise = userPromise || getUser();
	return userPromise.then(user => axios.get(`/aggregates/agency/${user.agency_id}`).then(res => res.data)).catch(() => null);
}

let agencyAggregates = null;
const fetchAgencyAggregates = locationIfFail => async (to, from, next) => {
	if (!locationIfFail) {
		getAgencyAggregates();
		return next();
	}
	agencyAggregates = agencyAggregates === null ? await getAgencyAggregates() : agencyAggregates;
	if (!agencyAggregates && locationIfFail) return next(locationIfFail);
	next();
}

import multiguard from 'vue-router-multiguard';

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			name: 'Home',
			component: Home,
			props: route => {
				return {
					...convertQueryToProps(route)
				}
			},
			beforeEnter: multiguard([
				redirectIfStage2(),
			]),
		},
		{
			path: '/agency',
			name: 'Agency',
			component: Home,
			props: route => {
				return {
					section: 1,
					...convertQueryToProps(route)
				};
			},
			beforeEnter: multiguard([
				redirectIfStage2(),
				redirectIfSpotifyUser(null, {name: 'Home'}),
				redirectIfUser({name: 'Welcome Back'}),
				redirectIfSkippedAhead,
			]),
		},
		{
			path: '/quiz/:questionNumber?',
			name: 'Quiz',
			component: Home,
			props: route => {
				const questionNumber = Number(route.params.questionNumber);
				return {
					section: 2,
					questionNumber: isNaN(questionNumber) || questionNumber < 1 || questionNumber > questions.length ? 1 : questionNumber,
					...convertQueryToProps(route)
				};
			},
			beforeEnter: multiguard([
				redirectIfStage2(),
				redirectIfSpotifyUser(null, {name: 'Home'}),
				redirectIfUser({name: 'Welcome Back'}),
				redirectIfSkippedAhead,
			]),
		},
		{
			path: '/welcome-back',
			name: 'Welcome Back',
			component: Home,
			props: route => {
				return {
					section: 3,
					user,
					...convertQueryToProps(route)
				};
			},
			beforeEnter: multiguard([
				redirectIfUser(null, {name: 'Agency'}),
				redirectIfStage2(null, {name: 'Stay Tuned'}),
				fetchGlobalAggregates(),
			])
		},
		{
			path: '/your-music',
			name: 'Your Music',
			component: Home,
			props: route => {
				return {
					section: 4,
					user,
					globalAggregates,
					...convertQueryToProps(route)
				};
			},
			beforeEnter: multiguard([
				redirectIfUser(null, {name: 'Agency'}),
				redirectIfStage2(null, {name: 'Stay Tuned'}),
				fetchGlobalAggregates({name: 'Home'}),
				fetchAgencyAggregates(),
			])
		},
		{
			path: '/your-office',
			name: 'Your Office',
			component: Home,
			props: route => {
				return {
					section: 5,
					user,
					globalAggregates,
					agencyAggregates,
					...convertQueryToProps(route)
				};
			},
			beforeEnter: multiguard([
				redirectIfUser(null, {name: 'Agency'}),
				redirectIfStage2(null, {name: 'Stay Tuned'}),
				fetchGlobalAggregates({name: 'Home'}),
				fetchAgencyAggregates({name: 'Home'}),
			])
		},
		{
			path: '/stay-tuned',
			name: 'Stay Tuned',
			component: StayTuned,
			beforeEnter: multiguard([
				redirectIfSpotifyUser(null, {name: 'Home'}),
				redirectIfStage2({name: 'Welcome Back'})
			])
		},
		{
			path: '*',
			redirect: '/'
		}
	]
});

export default router;
