<template>
	<div class="scrolling-container" ref="main">
		<div class="home" :style="`--scroll-y: ${scrollY}px`">
			<Landing ref="landing" @continue="afterLogin" :loading.sync="loggingIn" :show-footer="!$root.isMobile && section === 0" style="z-index: 2;" :isStage2="isStage2" />
			<transition :duration="{ leave: 500 }">
				<Agency v-if="section === 1 || section === 2" ref="agency" v-model="agencyModel" @continue="scrollToRef('quiz')" :show-footer="!$root.isMobile && section === 1" />
			</transition>
			<transition :duration="{ leave: 500 }">
				<Quiz v-if="section === 2" ref="quiz" :question-number="questionNumber" :answers="answers" @continue="submitAnswers" />
			</transition>
			<transition :duration="{ leave: 500 }">
				<WelcomeBack v-if="section >= 3" ref="welcomeBack" :agency-id="user.agency_id" :show-footer="!$root.isMobile && section === 3" @continue="scrollToRef('yourMusic')" />
			</transition>
			<transition :duration="{ leave: 500 }">
				<YourMusic v-if="section >= 4" ref="yourMusic" @continue="scrollToRef('welcomeOffice')" :user="user" :global-aggregates="globalAggregates" />
			</transition>
			<transition :duration="{ leave: 1000 }">
				<WelcomeOffice v-if="section >= 4" ref="welcomeOffice" :show-footer="!$root.isMobile && section === 4" @continue="scrollToRef('yourOffice', { align: 'top' })" />
			</transition>
			<transition :duration="{ leave: 1000 }">
				<YourOffice
					v-if="section >= 5"
					ref="yourOffice"
					:show-footer="!$root.isMobile && section === 5"
					v-bind="{ object, subject, globalAggregates, agencyAggregates, user }"
					@scrollTo="scrollToEl($event)"
				/>
			</transition>
			<SpotifyFooter v-show="$root.isMobile" />
		</div>
	</div>
</template>

<style lang="scss" scoped>
.home {
	overflow: hidden;
	position: relative;
}

.main-view-enter,
.main-view-leave-to {
	transform: translateX(-100%);
}
</style>

<script>
import Landing from '@/sections/Landing.vue';
import Agency from '@/sections/Agency.vue';
import Quiz from '@/sections/Quiz.vue';
import WelcomeBack from '@/sections/WelcomeBack.vue';
import YourMusic from '@/sections/YourMusic.vue';
import WelcomeOffice from '@/sections/WelcomeOffice.vue';
import YourOffice from '@/sections/YourOffice.vue';

export default {
	components: {
		Landing,
		Agency,
		Quiz,
		WelcomeBack,
		YourMusic,
		WelcomeOffice,
		YourOffice
	},
	props: {
		section: {
			type: Number,
			default: 0
		},
		questionNumber: Number,
		agency: Number,
		answers: Array,
		user: Object,
		globalAggregates: Object,
		agencyAggregates: Object,
		object: Number,
		subject: Number,
		isStage2: Boolean,
	},
	data() {
		return {
			scrollY: null,
			mainEl: null,
			loggingIn: false
		};
	},
	computed: {
		agencyModel: {
			get() {
				return this.agency;
			},
			set(value) {
				this.scrollToRef('agency');
				this.$router.replace({ ...this.$route, query: { ...this.$route.query, agency: value } });
			}
		}
	},
	watch: {
		section(value) {
			setTimeout(() => this.scrollToSection(value), 0)
		},
		questionNumber(value) {
			try {
				this.scrollToRef('quiz');
			} catch(err) { }
		}
	},
	mounted() {
		this.mainEl = this.$refs.main;
		this.scrollToSection(this.section, false);
		this.updateScrollY();
		this.mainEl.addEventListener('scroll', this.updateScrollY);
	},
	destroyed() {
		this.mainEl.removeEventListener('scroll', this.updateScrollY);
	},
	methods: {
		updateScrollY() {
			this.scrollY = this.mainEl.scrollTop;
		},
		scrollToSection(sectionNumber, smooth = true) {
			switch (sectionNumber) {
				case 0:
					this.scrollToRef('landing', { smooth });
					break;
				case 1:
					this.scrollToRef('agency', { smooth });
					break;
				case 2:
					this.scrollToRef('quiz', { smooth });
					break;
				case 3:
					this.scrollToRef('welcomeBack', { smooth });
					break;
				case 4:
					this.scrollToRef('yourMusic', { smooth });
					break;
				case 5:
					this.scrollToRef('yourOffice', { smooth, align: 'top' });
					break;
			}
		},
		submitAnswers() {
			const [country, star_sign, gender, decade] = this.answers;

			this.$axios
				.post('/submit', {
					agency_id: this.agency,
					country,
					star_sign,
					gender,
					decade
				})
				.then(() => {
					localStorage.setItem('hasSubmitted', true);
					this.$router.push({ name: 'Stay Tuned' });
				});
		},
		afterLogin() {
			this.$router
				.push({ name: 'Welcome Back' })
				.then(() => {
					this.$gaEvent('User logged in', 'Returning user');
				})
				.catch(err => {
					this.$gaEvent('User logged in', 'New User');
					try {
						this.scrollToRef('agency');
					} catch(err) {}
				})
				.finally(() => (this.loggingIn = false));
		},
		scrollToRef(ref, options) {
			if (!this.$refs[ref]) throw new Error('Unknown reference: ' + ref);
			this.scrollToEl(this.$refs[ref].$el || this.$refs[ref], options);
		},
		scrollToEl(el, { smooth = true, align } = {}) {
			let elTop = 0;
			for (let currentEl = el; currentEl !== this.mainEl && currentEl !== document.body; currentEl = currentEl.parentElement) {
				elTop += currentEl.offsetTop;
			}
			const elBottom = elTop + el.offsetHeight;
			let newScrollTop;
			switch (align) {
				case 'bottom':
					newScrollTop = el.offsetHeight < window.innerHeight ? elBottom - window.innerHeight : elTop;
					break;
				case 'center':
				default:
					newScrollTop = elTop - window.innerHeight / 2 + el.offsetHeight / 2;
					break;
				case 'top':
					newScrollTop = elTop;
					break;
			}

			this.mainEl.scrollTo({ top: newScrollTop, behavior: smooth ? 'smooth' : 'auto' });
		}
	}
};
</script>
