<template>
	<div class="scrolling-banner" :class="{vertical}">
		<div v-for="i in repeat" :key="i" ref="repeatedSlots">
			<slot></slot>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.scrolling-banner {
	overflow: hidden;
	display: flex;
	width: 100%;
	padding-left: 0;
	padding-right: 0;
	position: relative;

	&.vertical {
		flex-direction: column;
	}	
}

strong {
	text-decoration: underline;
	color: white;
}
</style>

<script>
export default {
	props: {
		vertical: Boolean,
		repeat: {
			type: Number,
			default: 2,
			validate: x => x >= 2,
		},
		scrollSpeed: {
			type: Number,
			default: 1, // ems per second 
		}
	},
	data() {
		return {
			scrollPosition: 0, // scrollLeft for horizontal, scrollTop for vertical
			scrollEnd: 0,
			lastTimestamp: null,
			rafCallbackId: null,
			fontSize: 10,
		}
	},
	mounted() {
		this.updateScrollEnd();
		this.$el.addEventListener('scroll', this.updateScrollEnd);
		this.rafUpdate();
		window.addEventListener('resize', this.updateFontSize);
		this.updateFontSize();
	},
	destroyed() {
		window.cancelAnimationFrame(this.rafCallbackId);
		window.removeEventListener('resize', this.updateFontSize);
	},
	computed: {
		scrollProgress() {
			return this.scrollPosition / this.scrollEnd;
		}
	},
	watch: {
		scrollPosition(value) {
			if(this.vertical) {
				this.$el.scrollTop = value;
			} else {
				this.$el.scrollLeft = value;
			}
		},
		scrollProgress(value) {
			if (value >= 1) {
				this.scrollPosition -= this.scrollEnd;
			}
		},
	},
	methods: {
		updateFontSize() {
			this.fontSize = parseFloat(window.getComputedStyle(this.$el).fontSize);
		},
		updateScrollEnd() {
			const secondRepeatedSlot = this.$refs.repeatedSlots[1];
			if (!secondRepeatedSlot) return;
			this.scrollEnd = secondRepeatedSlot[this.vertical ? 'offsetTop' : 'offsetLeft'];
		},
		rafUpdate(timestamp) {
			if (timestamp) {
				if (!this.lastTimestamp) this.lastTimestamp = timestamp;
				const step = timestamp - this.lastTimestamp; // in ms
				const scrollStep = (step / 1000) * this.scrollSpeedInPixels(); // in px
				this.scrollPosition += scrollStep;
				this.lastTimestamp = timestamp;
			}
			this.rafCallbackId = window.requestAnimationFrame(this.rafUpdate);
		},
		scrollSpeedInPixels() {
			return this.scrollSpeed * this.fontSize;
		}
	}
}
</script>