export default {
	data() {
		return {
			isMobile: false,
			isLaptop: false,
		}
	},
	created() {
		window.addEventListener("resize", this.update);
		this.update();
	},
	destroyed() {
		window.removeEventListener("resize", this.update);
	},
	methods: {
		update() {
			this.isMobile = window.innerWidth <= 768;
			this.isLaptop = window.innerWidth <= 1280;
		},
	},
}