import countries from 'country-json/src/country-by-name.json';



export default [
	{
		text: `<em>Which country are you from?</em>`,
		options: ['Australia'].concat(countries.map(x => x.country).filter(x => x !== 'Australia')),
	},
	{
		text: `<em>What star sign are you?</em>`,
		options: ['Aries', 'Taurus', 'Gemini', 'Cancer', 'Leo', 'Virgo', 'Libra', 'Scorpio', 'Sagittarius', 'Capricorn', 'Aquarius', 'Pisces']
	},
	{
		text: `<em>What gender are you?</em>`,
		options: ['Male', 'Female', 'Other', 'Prefer not to say']
	},
	{
		text: '<em>What decade were you born?</em>',
		options: ['60s', '70s', '80s', '90s']
	}
];
