<template>
	<div class="section padded">
		<p class="h2 question" style="max-width: 12em;">
			hey, welcome back!
			your <span style="color: white; text-decoration: underline">{{agency.text}}</span>
			<span style="color: black">groupm</span>ix is ready
		</p>
		<VLink @click="goToNext" rounded filled>Are you ready?</VLink>
		<SpotifyFooter v-show="showFooter" />
	</div>
</template>

<script>
import {agenciesById} from '@/data/agencies.js';

export default {
	props: ['agencyId', 'showFooter'],
	computed: {
		agency() {
			return agenciesById[this.agencyId];
		}
	},
	methods: {
		goToNext() {
			this.$router.push({name: 'Your Music'}).catch(x => x).finally(() => this.$emit('continue'));
		}
	}
}
</script>

<style scoped>
.section {
	color: var(--color-pink);
}
</style>