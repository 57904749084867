<template>
	<div class="section">
		<OfficeMusic :agency-aggregates="agencyAggregates"/>
		<OfficeCompare style="z-index: 1; overflow: visible" ref="compare" :object.sync="objectModel" :subject.sync="subjectModel" :global-aggregates="globalAggregates" :user="user"/>
		<Playlists :user="user" :agency-aggregates="agencyAggregates"/>
		<SpotifyFooter v-show="showFooter" />
	</div>
</template>

<script>
import OfficeCompare from '@/sections/OfficeCompare.vue';
import OfficeMusic from '@/sections/OfficeMusic.vue';
import Playlists from '@/sections/Playlists.vue';

export default {
	props: {
		showFooter: Boolean,
		object: Number,
		subject: Number,
		globalAggregates: Object,
		agencyAggregates: Object,
		user: Object
	},
	components: {
		OfficeCompare,
		OfficeMusic,
		Playlists,
	},
	computed: {
		objectModel: {
			get() {
				return this.object;
			},
			set(value) {
				this.$refs.compare && this.$emit('scrollTo', this.$refs.compare.$el);
				this.$router.replace({...this.$route, query: {...this.$route.query, object: value}});
			}
		},
		subjectModel: {
			get() {
				return this.subject;
			},
			set(value) {
				this.$refs.compare && this.$emit('scrollTo', this.$refs.compare.$el);
				this.$router.replace({...this.$route, query: {...this.$route.query, subject: value}});
			}
		},
	}
};
</script>
