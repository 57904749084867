<template>
	<div class="section padded" :style="`--num-of-questions: ${questions.length}`">
		<p class="h2 question">
			hey, which
			<VSelect
				v-model="model"
				class="select"
				:class="{ shake }"
				:options="agencies"
				label="text"
				:reduce="x => x.id"
				:clearable="false"
				:placeholder="$root.isMobile ? 'agency' : 'groupm agency'"
				@animationend.native="shake = false"
			>
				<option value="null">groupm agency</option>
			</VSelect>
			are you from?
		</p>
		<VLink @click="goToQuiz" rounded filled class="continue-button" :disabled="!model">That's correct</VLink>
		<SpotifyFooter v-show="showFooter" />
	</div>
</template>

<script>
import questions from '@/data/questions.js';
import agencies from '@/data/agencies.js';

export default {
	props: ['value', 'showFooter'],
	data() {
		return {
			agencies,
			questions,
			shake: false
		};
	},
	computed: {
		model: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			}
		}
	},
	methods: {
		goToQuiz() {
			if (this.model) {
				if (this.$route.name !== 'Quiz') {
					this.$router.push({ name: 'Quiz', query: this.$route.query}).then(() => {
						this.$gaEvent('Continued to Quiz');
						this.$emit('continue');
					});
				} else {
					this.$emit('continue');
				}
			} else {
				this.shake = true;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.section {
	color: var(--color-pink);
	overflow: visible;
	position: relative;
}

.question,
.select {
	width: 100%;
	z-index: 20;
}

.mobile .question {
	margin: 5rem 0;
}

.continue-button {
	z-index: 2;
}
</style>
