<template>
	<div class="section full-height">
		<div class="white-bg"></div>
		<BackgroundTriangles />
		<div class="questions" ref="quiz">
			<div class="padded" v-for="(question, index) in questions" :key="index" ref="questions">
				<header class="header">
					<VLink @click="previousQuestion" class="back-button">&lt;</VLink>
					<p>{{ index + 1 }} of {{ questions.length }}</p>
				</header>
				<div class="h2 question-text">
					<p v-html="question.text"></p>
					<VSelect v-model="answersModel[index]" class="select" :class="{ shake }" :options="question.options" :clearable="false" @animationend.native="shake = false">
						<option value="null">groupm agency</option>
					</VSelect>
				</div>
				<div class="row">
					<VLink @click="nextQuestion" class="next-button" rounded :disabled="!currentAnswer">Next question</VLink>
					<svg-vue icon="tail-spin" class="loading-spinner" v-if="loading"></svg-vue>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.section {
	position: relative;
	background-color: var(--color-blue);
	align-items: flex-start;

	& > * {
		z-index: 0;
	}
}

.questions {
	display: flex;
	flex-direction: row;
	overflow-x: hidden;
	flex: 1;
	width: 100%;

	& > * {
		width: 100vw;
		flex: none;
	}
}

.white-bg {
	padding-top: 3rem;
}
.mobile .white-bg {
	padding-left: 1.5rem;
	padding-top: 1.5rem;
}

.questions {
	z-index: 1;

	& > * {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding-bottom: 9rem;

		& > * {
			margin: auto 0;
		}
	}
}

.question-text {
	width: 100%;
}

.header {
	display: flex;
	align-items: center;
	position: relative;
	margin-top: 0;
	color: var(--color-blue);

	& > * {
		margin: 0;
		padding: 0;
	}

	& > *:first-child {
		margin-right: 4rem;
	}
}
.not-mobile .header {
	left: -4.5rem;
}

.select {
	--color: black;
}

.loading-spinner {
	width: 2rem;
	height: 2rem;
	color: black;
}

.row {
	align-items: center;

	& > * {
		margin-right: 2rem;
	}
}
</style>

<script>
import questions from '@/data/questions.js';
import lottieOptions from '@/data/lottieOptions.js';

export default {
	props: {
		questionNumber: {
			type: Number,
			default: 1,
			validator: value => value > 0 && value <= questions.length
		},
		showFooter: Boolean,
		answers: Array
	},
	data() {
		return {
			questions,
			quizEl: null,
			lottieOptions,
			shake: false,
			answersModel: this.answers,
			loading: false,
		};
	},
	computed: {
		questionIndex() {
			return this.questionNumber - 1;
		},
		currentAnswer() {
			return this.answers[this.questionIndex];
		}
	},
	watch: {
		questionNumber(value) {
			this.scrollToQuestion(value);
		},
		answers(value) {
			this.answersModel = value;
		},
		answersModel(value, oldValue) {
			if (JSON.stringify(value) !== this.$route.query.answers) {
				this.$router.replace({ name: 'Quiz', params: this.$route.params, query: { ...this.$route.query, answers: JSON.stringify(this.answersModel) } });
			}
		}
	},
	mounted() {
		this.quizEl = this.$refs.quiz.$el || this.$refs.quiz;
		this.scrollToQuestion(this.questionNumber, false);
	},
	methods: {
		nextQuestion() {
			if (!this.currentAnswer) {
				this.shake = true;
				return;
			}

			if (this.questionNumber < questions.length) {
				this.$gaEvent('Answered quiz question', `Number ${this.questionNumber}`);
				this.$router.push({ name: 'Quiz', params: { questionNumber: this.questionNumber + 1 }, query: this.$route.query });
			} else {
				this.loading = true;
				this.$gaEvent('Finished quiz');
				this.$emit('continue');
			}
		},
		previousQuestion() {
			this.$gaEvent('Back to previous question');
			this.$router.go(-1);
		},
		scrollToQuestion(number, smooth = true) {
			this.$nextTick(() => {
				const el = this.$refs.questions[number - 1].$el || this.$refs.questions[number - 1],
					newScrollLeft = el.offsetLeft;

				this.quizEl.scrollTo({ left: newScrollLeft, behavior: smooth ? 'smooth' : 'auto' });
			});
		},
	}
};
</script>
