<template>
	<div class="section padded">
		<p class="h2 question" style="max-width: 12em;">
			Want to know how your <span style="color: white; text-decoration: underline">office</span> compares to other <span style="color: white; text-decoration: underline">GroupM agencies</span>?
		</p>
		<VLink @click="goToNext" rounded filled>Find Out</VLink>
		<SpotifyFooter v-show="showFooter" inherit-background-color/>
	</div>
</template>

<script>
import agencies from '@/data/agencies.js';

export default {
	props: ['agencyId', 'showFooter'],
	data() {
		return {
			agencies,
		}
	},
	computed: {
		agency() {
			return this.agencies[this.agencyId];
		}
	},
	methods: {
		goToNext() {
			this.$router.push({name: 'Your Office'}).catch(x => x).finally(() => this.$emit('continue'));
		}
	}
}
</script>

<style scoped>
.section {
	color: var(--color-pink);
	background-color: black;
	padding-bottom: 6rem;
}
</style>