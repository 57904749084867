<template>
	<transition :duration="{enter: 600, leave: 100}">
		<div class="footer-container" :style="inheritBackgroundColor && 'background-color: inherit'">
			<footer :style="inheritBackgroundColor && 'background-color: inherit'">
				<a href="https://www.spotify.com/uk/" target="_blank" @click="$gaEvent('Footer', 'Listen On Spotify')">
					<svg-vue icon="listen-on-spotify" class="listen-on-spotify"></svg-vue>
				</a>
				<div class="links">
					<a href="https://www.spotify.com/uk/legal/privacy-policy" target="_blank" @click="$gaEvent('Footer', 'Privacy Policy')">Privacy</a>
					<a href="https://www.spotify.com/uk/legal/end-user-agreement/" target="_blank" @click="$gaEvent('Footer', 'User Agreement')">Legal</a>
					<a href="https://www.spotify.com/uk/legal/cookies-policy" target="_blank" @click="$gaEvent('Footer', 'Cookies Policy')">Cookies</a>
					<a @click="showRevokeAccessDialog">Revoke Access</a>
				</div>
			</footer>
		</div>
	</transition>
</template>

<script>
export default {
	props: {
		inheritBackgroundColor: Boolean,
	},
	methods: {
		showRevokeAccessDialog() {
			this.$gaEvent('Footer', 'Revoke Access');
			alert(
				'In order to facilitate a personal experience for you when you engage with this website, we may use your Spotify Account Data when you opt to ‘connect’ your account.\n\n' +
					'If you would like to disconnect your Spotify Account from this website, please visit spotify.com/account/ → Apps → Revoke Access. In this case, you will need to locate: Spotify Renault.'
			);
		}
	}
};
</script>

<style lang="scss" scoped>
.footer-container {
	overflow: hidden;
	width: 100%;
	z-index: 10;
	margin-bottom: 0 !important;
	position: relative;
}

.not-mobile .footer-container {
	position: absolute;
	bottom: 0;
	left: 0;
}

footer {
	color: white;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	flex-shrink: 0;
	width: 100%;
	padding: 0.5rem 1rem;
	background: var(--color-blue);
	transform: translateY(2px);
	font-size: 0.7rem;
}

.mobile footer {
	flex-direction: column;
	padding: 1rem 2rem;
}

.links {
	display: flex;
}
.mobile .links {
	flex-direction: column;
	width: 100%;
}

a {
	color: #fff;
	opacity: 1;
	transition: opacity 0.3s;
	text-transform: uppercase;
	margin: 0 0.5rem;
	cursor: pointer;
}

.mobile a {
	padding: 1rem;
	border-bottom: 1px solid black;
	width: 100%;
	text-align: center;
}

@media (hover) {
	a:hover {
		opacity: 0.5;
	}
}

.listen-on-spotify {
	height: 1.5rem;
}

.not-mobile {
	.v-enter-active,
	.v-leave-active {
		footer {
			transition: transform 0.075s ease 0.5s;
		}
	}

	.v-leave-active footer {
		transition-delay: 0s;
	}

	.v-leave-to,
	.v-enter {
		footer {
			transform: translateY(102%);
		}
	}
}
</style>
