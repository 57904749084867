<template>
	<div class="card-slider">
		<div class="slide-container" ref="slideContainer" :style="`-webkit-overflow-scrolling: ${inertialScrolling ? 'touch' : 'auto'} `">
			<slot></slot>
		</div>
		<button class="left" @click="goToPreviousSlide" :disabled="disableLeftButton">
			<img src="@/images/arrow-left.png" />
		</button>
		<button class="right" @click="goToNextSlide" :disabled="disableRightButton">
			<img src="@/images/arrow-right.png" />
		</button>
	</div>
</template>

<style scoped lang="scss">
.card-slider {
	position: relative;
	width: 100%;
}

.slide-container {
	display: flex;
	overflow-x: hidden;
	height: 100%;
	width: 100%;

	& > * {
		flex-grow: 0;
		flex-shrink: 0;
	}
}
.mobile .slide-container {
	overflow-x: auto;
}

.h3 {
	max-width: 20rem;
}

button {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 3rem;
	height: 3rem;
	z-index: 10;

	@media (hover) {
		&:not([disabled]):hover {
			transform: translateY(-50%) scale(1.05);
		}
	}

	img {
		width: 100%;
		height: 100%;
	}

	&.left {
		left: 1rem;
	}

	&.right {
		right: 1rem;
	}
}
</style>

<script>
import {debounce} from 'lodash';

export default {
	data() {
		return {
			slides: [], // the slide elements, these are the direct children of the default slot.
			slideOffsets: [], // the scroll positions of each of the slides
			scrollPosition: 0, // number of pixels scrolled (scrollLeft for a horizontal slider)
			seekedSlide: 0,
			mutationObserver: null,
			inertialScrolling: true,
		}
	},
	mounted() {
		this.initialize();
		window.addEventListener('resize', this.onResizeListener);
		this.$refs.slideContainer.addEventListener('scroll', this.updateScrollPosition);
		this.$refs.slideContainer.addEventListener('scroll', debounce(this.snapToCurrentSlide, 50));
		this.startMutationObserver();
	},
	destroyed() {
		window.removeEventListener('resize', this.onResizeListener);
		this.stopMutationObserver();
	},
	computed: {
		slideOffsetMidpoints() {
			const result = [];
			for (let i = 1; i < this.slideOffsets.length; i++) {
				result.push(0.5 * (this.slideOffsets[i-1] + this.slideOffsets[i]));
			}
			return result
		},
		currentSlideIndex() {
			const result = this.slideOffsetMidpoints.findIndex(x => x > this.scrollPosition);
			return result >= 0 ? result : this.slides.length-1; // if couldn't find index, then we are past the last midpoint and on the last slide.
		},
		disableLeftButton() {
			return this.seekedSlide === 0;
		},
		disableRightButton() {
			return this.seekedSlide === this.slides.length - 1;
		}
	},
	watch: {
		seekedSlide(value) {
			this.scrollTo(value);
			this.$emit('slide-change', value);	
		},
		currentSlideIndex(value, oldValue) {
			if (oldValue === this.seekedSlide) this.seekedSlide = value;
		}
	},
	methods: {
		initialize() {
			this.updateSlides();
			this.updateSlideOffsets();
		},
		startMutationObserver() {
			this.mutationObserver = new MutationObserver(() => setTimeout(this.initialize, 500));
			this.mutationObserver.observe(
				this.$refs.slideContainer,
				{ attributes: true, childList: true, characterData: true, subtree: true }
			);
		},
		stopMutationObserver() {
			this.mutationObserver.disconnect();
		},
		updateSlides() {
			this.slides = Array.from(this.$el.querySelectorAll('.slide-container > *'));
		},
		updateSlideOffsets() {
			this.slideOffsets = this.slides
				.reduce((acc, slide) => {
					const previousOffset = acc.length ? acc[acc.length - 1] : 0;
					acc.push(previousOffset + slide.offsetWidth);
					return acc;
				}, [0])
				.slice(0, -1);
		},
		updateScrollPosition() {
			this.scrollPosition = this.$refs.slideContainer.scrollLeft;
		},
		goToRelativeSlide(step) {
			this.seekedSlide = Math.max(0, Math.min(this.slides.length - 1, this.seekedSlide += step))
		},
		goToNextSlide() {
			this.goToRelativeSlide(1);
		},
		goToPreviousSlide() {
			this.goToRelativeSlide(-1);
		},
		snapToCurrentSlide(smooth = true) {
			this.seekedSlide = this.currentSlideIndex;
			this.scrollTo(this.currentSlideIndex, smooth);
		},
		scrollTo(index, smooth = true) {
			this.arrestMomentum();
			this.$refs.slideContainer.scrollTo({left: this.slideOffsets[index], behavior: smooth ? 'smooth' : 'auto'})
		},
		arrestMomentum() {
			this.inertialScrolling = false;
			window.requestAnimationFrame(() => this.inertialScrolling = true);
		},
		onResizeListener() {
			this.updateSlideOffsets();
			this.snapToCurrentSlide(false);
		}
	}
}
</script>