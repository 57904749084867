<template>
	<div class="timer">
		<svg :viewBox="`-${width/2} -${width/2} ${width} ${width}`" xmlns="http://www.w3.org/2000/svg" version="1.1" :stroke-width="strokeWidth">
			<circle cx="0" cy="0" :r="radius" fill="transparent" :stroke-width="strokeWidth" stroke="black"/>
			<path :d="`M 0 -${radius} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${arcEndX} ${arcEndY}`" fill="transparent" :stroke-width="strokeWidth" />
		</svg>
		<div class="days-left">{{daysLeft}}</div>
	</div>
</template>

<style scoped>
path {
	stroke: var(--color-blue);
	fill: transparent;
}

.timer {
	position: relative;
	line-height: 0;
	color: black;
	width: 1.7em;
}

.days-left {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
</style>

<script>
export default {
	props: {
		daysLeft: {
			type: Number,
			default: 0,
		},
		daysTotal: {
			type: Number,
			default: 0,
		}
	},
	data() {
		return {
			strokeWidth: 25,
			width: 200,
		}
	},
	computed: {
		progress() {
			return Math.max(Math.min((this.daysTotal - this.daysLeft) / this.daysTotal, 0.999), 1 / this.daysTotal);
		},
		radius() {
			return (this.width - this.strokeWidth)/2;
		},
		arcEndX() {
			return this.radius * Math.sin(Math.PI * 2 * this.progress);
		},
		arcEndY() {
			return -1 * this.radius * Math.cos(Math.PI * 2 * this.progress);
		},
		largeArcFlag() {
			return this.progress >= 0.5 ? 1 : 0;
		}
	}
}
</script>
