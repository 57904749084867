<template>
	<div class="section">
		<div class="white-bg"></div>
		<div class="column first padded">
			<BackgroundTriangles style="z-index: -1; width: 160%; right: -42%; left: auto; transform: translateX(calc(10vh - 10vw));" anchor="right" />
			<p class="h2">you... or at least <span style="color: black">your music</span></p>
		</div>
		<div class="column second">
			<Slider style="height: 100%;">
				<article v-if="user.top_artist_long_term">
					<div class="h3">Your Top Artist of all time is <br><strong>{{user.top_artist_long_term}}</strong></div>
				</article>
				<article v-if="user.top_artist_short_term">
					<div class="h3">{{user.top_artist_long_term ? "But y" : "Y"}}our favourite artist right now is <br><strong>{{user.top_artist_short_term}}</strong></div>
				</article>
				<article v-if="user.top_genre_category">
					<div class="h3">Your favourite genre is <br><strong style="font-size: 4rem;">{{user.top_genre_category | capitalize}}</strong></div>
				</article>
				<article v-if="user.top_genre">
					<div class="h3">{{user.top_genre_category ? "But w" : "W"}}hat makes you stand out is <br><strong style="font-size: 4rem;">{{user.top_genre | capitalize}}</strong></div>
				</article>
				<article v-if="user.top_track_short_term">
					<div class="h3">You have been listening to a lot of <br><strong>{{user.top_track_short_term.split(' - ').slice(0, -1).join(' - ')}}</strong> <br><small>by {{user.top_track_short_term.split(' - ').slice(-1)[0]}}</small></div>
				</article>
				<article v-if="user.top_track_long_term">
					<div class="h3">{{user.top_track_short_term ? "And y" : "Y"}}our all-time favourite tune is <br><strong>{{user.top_track_long_term.split(' - ').slice(0, -1).join(' - ')}}</strong> <br><small>by {{user.top_track_long_term.split(' - ').slice(-1)[0]}}</small></div>
				</article>
			</Slider>
		</div>
		<SpotifyFooter v-show="showFooter" />
	</div>
</template>

<script>
import Slider from '@/components/Slider.vue';
import {mapValues, capitalize} from 'lodash';

export default {
	props: {
		showFooter: Boolean,
		user: Object,
		globalAggregates: Object,
	},
	components: {
		Slider
	},
	filters: {
		capitalize
	}
};
</script>

<style scoped lang="scss">
.section {
	color: var(--color-blue);
	flex-direction: row;
	align-items: stretch;
	min-height: calc(70 * var(--vh, 1vh));

	.column {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;

		&.first {
			z-index: 1;
			pointer-events: none;
			align-items: center;
		}
	}
}

.not-mobile .column.first {
	padding: 12rem 0;
}

.mobile .column {
	height: 50%;

	&.second {
		padding-left: 2rem;
		background-color: var(--color-pink);
	}
}

.not-mobile .column {
	width: 50%;
}

.mobile .section {
	flex-direction: column;
	height: calc(100 * var(--vh));
}

.not-mobile .h2 {
	max-width: 6em;
	padding-left: 6rem;
}

article {
	height: 100%;
	width: 66.67%;
	min-width: 29rem;
	flex-shrink: 0;
	padding: 6rem;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	position: sticky;
	left: 0;

	strong {
		font-weight: 600;
		font-size: 3rem;
	}

	small {
		font-size: 1.3rem;
	}

	&:nth-child(odd) {
		background-color: var(--color-blue);
		color: var(--color-pink);
		
		strong {
			color: white;
		}
	}

	&:nth-child(even) {
		background-color: var(--color-pink);

		strong {
			color: black;
		}
	}

	&:last-child {
		width: 100%;
	}
}

.not-mobile article:last-child {
	padding-right: 33.33%;
}
</style>
